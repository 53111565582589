import Glide from '@glidejs/glide';

export const initCommentsSlider = () => {
    const sliderSelector = '[data-glide-slider="comments"]';
    const sliderBox = document.querySelector(`${sliderSelector}`);
    if (!sliderBox) return;

    const glideSlider = new Glide(`${sliderSelector}`, {
        type: 'carousel',
        startAt: 0,
        perView: 1,
        focusAt: 1,
        autoplay: false,
    });

    glideSlider.mount();
};
