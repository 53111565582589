export const initMobileMenu = () => {
    // VARS
    const burgerButton = document.querySelector('[data-burger]');
    const menuDropDown = document.querySelector('[data-header-toolbar]');
    const activeClassButton = 'burger__button--active';
    const activeClassMenu = 'header__toolbar--opened';

    // LISTENERS
    burgerButton.addEventListener('click', handleOnClickMenuButton, false);

    // HANDLERS
    function handleOnClickMenuButton() {
        toggleMenu();
    }

    // METHODS & FUNCTIONS
    function toggleMenu() {
        burgerButton.classList.toggle(activeClassButton);

        if (burgerButton.classList.contains(activeClassButton)) {
            menuDropDown.classList.add(activeClassMenu);
        } else {
            menuDropDown.classList.remove(activeClassMenu);
        }
    }
};
