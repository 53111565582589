export const initChangePriceRange = () => {
    // VARS
    const priceInputs = document.querySelectorAll('[data-price-value]');
    if (!priceInputs.length) return;

    // LISTENERS
    priceInputs.forEach((input) => {
        input.addEventListener('change', handleOnchangePrice, false);
    });

    // HANDLERS
    function handleOnchangePrice() {
        changePrice(this);
    }

    // FUNCTIONS
    function changePrice(input) {
        const card = input.closest('[data-price-table-card]');
        const priceElem = card?.querySelector('[data-price]');
        if (!card || !priceElem) return;

        priceElem.textContent = input.value.trim();
    }
};
