export const initSubmenuDropDown = () => {
    // VARS
    const submenuDropDownButtons = document.querySelectorAll('[data-submenu-button]');
    const submenus = document.querySelectorAll('[data-submenu]');
    const activeClassDropDown = 'main-menu__submenu--opened';
    const breakPoint = 960;
    if (!submenuDropDownButtons || !submenus.length) return;

    // LISTENERS
    [...submenuDropDownButtons].forEach((submenuDropDownButton) => {
        submenuDropDownButton.addEventListener('click', handleOnClickDropDownButton, false);
    });

    document.addEventListener('click', handleOnClickDocument, false);

    document.addEventListener('keydown', handleOnKeydownDocument, false);

    // HANDLERS
    function handleOnClickDropDownButton() {
        toggleSubmenu(this);
    }

    function handleOnClickDocument(event) {
        if (window.innerWidth > breakPoint && !event.target.closest('[data-submenu-box]')) {
            closeAllSubmenu();
        }
    }

    function handleOnKeydownDocument(event) {
        if (event.key === 'Escape' && window.innerWidth > breakPoint) {
            closeAllSubmenu();
        }
    }

    // METHODS & FUNCTIONS
    function toggleSubmenu(toggle) {
        const submenu = toggle.closest('[data-submenu-box]').querySelector('[data-submenu]');

        submenu.classList.contains(activeClassDropDown) ? closeSubmenu(submenu) : openSubmenu(submenu);
    }

    function openSubmenu(submenu) {
        submenu.classList.add(activeClassDropDown);
    }

    function closeSubmenu(submenu) {
        submenu.classList.remove(activeClassDropDown);
    }

    function closeAllSubmenu() {
        [...submenus].forEach((submenu) => {
            submenu.classList.remove(activeClassDropDown);
        });
    }
};
