import { initLazyLoadFonts } from './components/initLazyLoadFonts';
import { initPricesTable } from './components/priceTable/initPricesTable';
import { initMobileMenu } from './components/initMobileMenu';
import { initSubmenuDropDown } from './components/initSubmenuDropDown';
import { initSliders } from './components/initSliders';
import { initForms } from './components/initForms';
import { initFunnelHash } from '../../../../Common/Resources/src_front/js/components/initFunnelHash';
import { initDetectionReferrerTraffic } from '../../../../Common/Resources/src_front/js/components/initDetectionReferrerTraffic';
import { initFetchCheckoutAssetsAndCaching } from '../../../../Common/Resources/src_front/js/components/initFetchCheckoutAssetsAndCaching';

document.addEventListener('DOMContentLoaded', function () {
    initLazyLoadFonts();
    initDetectionReferrerTraffic();
    initFunnelHash();
    initMobileMenu();
    initSubmenuDropDown();
    initSliders();
    initPricesTable();
    initForms();
});

window.addEventListener('load', function () {
    initFetchCheckoutAssetsAndCaching();
});
