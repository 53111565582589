export const initTabPricesTable = () => {
    // VARS
    const tablesNavItem = document.querySelectorAll('[data-price-table-tab-nav]');
    if (!tablesNavItem.length) return;
    const activeClass = 'is-active';

    // LISTENERS
    tablesNavItem.forEach((item) => {
        item.addEventListener('click', handleOnClickNav, false);
    });

    // HANDLERS
    function handleOnClickNav() {
        if (this.classList.contains(activeClass)) return;
        const table = this.closest('[data-price-table]');

        changeActiveNavItem(table, this);
        changeTableContent(table, this.dataset.priceTableTabNav);
    }

    // FUNCTIONS
    function changeActiveNavItem(table, navItem) {
        const activeTab = table.querySelector('.is-active[data-price-table-tab-nav]');
        activeTab.classList.remove(activeClass);
        navItem.classList.add(activeClass);
    }

    function changeTableContent(table, indexContent) {
        const currentContent = table.querySelector(`[data-price-table-card="${indexContent}"]`);
        if (currentContent.classList.contains(activeClass)) return;

        const activeContent = table.querySelector('.is-active[data-price-table-card]');
        activeContent.classList.remove(activeClass);
        currentContent.classList.add(activeClass);
    }
};
