import { decorateUrl } from '../gtm/decorateUrl';
import { generateGl } from '../gtm/generateGl';

export const initFetchPlanForms = () => {
    //temp. see SHOP-1568
    if (document.querySelector('[data-plan-form-params]')) return;

    // VARS
    const forms = document.querySelectorAll('[data-plan-form]');
    if (!forms.length) return;

    // EVENTS
    [...forms].forEach((form) => {
        form.addEventListener('submit', handleOnSubmitForm, false);
    });

    // HANDLERS
    async function handleOnSubmitForm(event) {
        event.preventDefault();
        await sendForm(this);
    }
};

export async function sendForm(form) {
    const url = document.querySelector('[data-plan-form-url]').dataset.planFormUrl;
    const formData = new FormData();
    const hiddenInputs = form.querySelectorAll('input[type="hidden"]');
    const email = form.querySelector('input[type="email"]');
    const radioInputs = form.querySelectorAll('input[type="radio"]:checked');

    [...hiddenInputs].forEach((hiddenInput) => {
        formData.append(hiddenInput.name, hiddenInput.value.trim());
    });

    [...radioInputs].forEach((radioInput) => {
        formData.append(radioInput.name, radioInput.value.trim());
    });

    if (email) {
        formData.append(email.name, email.value.trim());
    }

    const response = await fetch(url, {
        method: 'POST',
        body: formData,
    });

    if (response.ok) {
        const data = await response.json();
        const currentUrl = new URL(decorateUrl(window.location.origin));
        const params = new URLSearchParams(currentUrl.search);
        const ga = params.get('_ga') || '';
        const gl = generateGl() || '';
        let redirectionURL = data.url || data.checkoutUrl;

        if (ga) {
            redirectionURL += `&_ga=${ga}`;
        }

        if (gl) {
            redirectionURL += `&_gl=${gl}`;
        }

        if (window.pageLoader) {
            window.pageLoader.show();
        }

        window.location.href = redirectionURL;
    }
}
